export const colors = {
  blackText: '#333333',
  bluePrimary: '#337AB7',
  blueSecondary: '#5393C8',
  grayBGPrimary: '#F5F5F5',
  grayBGSecondary: '#F0F0F0',
  grayBorder: '#EBE9E9',
  grayLine: '#BDBDBD',
  grayDisabled: '#BBBBBB',
  grayText: '#555555',
  redError: "#FF4D3A"
}