import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors as c } from '../../styles/colors';

export const Container = styled.header`
  width: 100%;
  height: 70px;
  background-color: ${c.bluePrimary};
  display: flex;
  align-items: center;

  ${({ homepage }) => !homepage && `
    margin-bottom: 60px;
  `}

  ${({ homepage }) => homepage && `
    margin-bottom: 0;
  `}

  > div {
    height: 100%; // Container1200
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 70px;
  height: auto;
`;

export const Social = styled.div`
  flex: 1;
  display: none;
  justify-content: flex-end;
  align-items: center;
  margin-right: 45px;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const SocialButton = styled.a`
  display: flex;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  font-size: 38px;
  color: ${c.grayBGPrimary};
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3);

  svg {
    padding: 0;
  }
`;

export const MenuButton = styled.button`
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 20px;
  background-color: transparent;
  font-size: 36px;
  color: ${c.grayBGPrimary};
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
  margin-left: auto;

  @media (min-width: 768px) {
    margin-left: 0;
  }

  ${({ open }) => open && `
    background-color: ${c.blueSecondary};
  `}

  span {
    display: flex;
    align-items: center;
  }
`;
