import styled from 'styled-components';
import { Link } from "gatsby"
import { colors as c } from '../../styles/colors';

export const Container = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: ${c.blueSecondary};
  padding: 20px 0;

  ${({ open }) => open && `
    display: flex;
  `}

  @media (min-width: 768px) {
    width: auto;
    right: 20px;
  }
`;

export const StyledLink = styled(Link)`
  position: relative;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
  padding: 20px 40px;

  &:hover {
    background-color: ${c.bluePrimary};
  }
`;

export const Social = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const SocialButton = styled.a`
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px;
  background-color: transparent;
  font-size: 28px;
  color: ${c.grayBGPrimary};
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3);

  i {
    padding: 0;
  }
`;
