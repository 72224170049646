import React from 'react'
// Components
import { Container1200 } from '../Container1200';
// Style
import { Container,Copyright } from './Footer.styles';

const Footer = () => {
  const year = new Date().getFullYear();

  return(
    <Container>
      <Container1200 flex="true">
        <Copyright>©{year} DaveJudd.com</Copyright>
      </Container1200>
    </Container>
  )
}

export default Footer