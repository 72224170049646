import React, { useState } from 'react'
// Components
import { Container1200 } from '../Container1200';
import { NavMenu } from '../NavMenu';
// Hooks
import { useLocation } from "@reach/router"
// Images
import logoOffwhite from '../../images/icons/logo-offwhite.svg';
// Style
import {
  Container,
  Logo,
  LogoLink,
  MenuButton,
  Social,
  SocialButton
} from './Header.styles';

const Header = () => {
  const [menuOpen,setMenuOpen] = useState(false);
  const { pathname } = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  return(
    <Container homepage={pathname==="/"}>
      <Container1200 flex="true">
        <LogoLink to={"/"}>
          <Logo src={logoOffwhite} alt="logo" />
        </LogoLink>
        <Social>
          <SocialButton href="https://github.com/SirDaev" aria-label="github">
            <i className="fab fa-github"></i>
          </SocialButton>
          <SocialButton href="https://twitter.com/SirDaev" aria-label="twitter">
            <i className="fab fa-twitter"></i>
          </SocialButton>
          <SocialButton href="https://www.linkedin.com/in/dave-judd-4655a413/" aria-label="linkedin">
            <i className="fab fa-linkedin"></i>
          </SocialButton>
        </Social>
        <MenuButton onClick={toggleMenu} open={menuOpen} aria-label="menu button">
          <span><i className="fas fa-bars"></i></span>
        </MenuButton>
        <NavMenu open={menuOpen} toggleMenu={toggleMenu} />
      </Container1200>
    </Container>
  )
}

export default Header