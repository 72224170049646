import React from 'react'
// Style
import { Container } from './Main.styles';

const Main = ({ children }) => (
  <Container>
    {children}
  </Container>
)

export default Main