import React from 'react';
// Components
import { StaticQuery,graphql } from 'gatsby';
// Style
import {
  Container,
  Social,
  SocialButton,
  StyledLink
} from './NavMenu.styles';

const NavMenu = ({ open=false,toggleMenu }) => {
  const query = graphql`
    query MainMenuItemsQuery {
      allMainMenuJson {
        edges {
          node {
            id
            path
            text
          }
        }
      }
    }
  `;

  return(

    <Container open={open}>
      <StaticQuery
        query={query}
        render={data => (
          <>
            {data.allMainMenuJson.edges.map(({ node }) => {
              return (
                <StyledLink
                  key={node.id}
                  to={node.path}
                  onClick={toggleMenu}
                >
                  {node.text}
                </StyledLink>
              )
            })}
          </>
        )}
      >
      </StaticQuery>
      <Social>
        <SocialButton href="https://github.com/SirDaev" onClick={toggleMenu}>
          <i className="fab fa-github"></i>
        </SocialButton>
        <SocialButton href="https://twitter.com/SirDaev" onClick={toggleMenu}>
          <i className="fab fa-twitter"></i>
        </SocialButton>
        <SocialButton href="https://www.linkedin.com/in/dave-judd-4655a413/" onClick={toggleMenu}>
          <i className="fab fa-linkedin"></i>
        </SocialButton>
      </Social>
    </Container>
  );
}

export default NavMenu;