import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({
  author,
  description,
  image,
  lang,
  path = '',
  title,

}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            baseUrl
            description
            siteName,
            title
          }
        }
      }
    `
  )

  const defaults = data.site.siteMetadata;
  if (defaults.baseUrl === '' && typeof window !== 'undefined') {
    defaults.baseUrl = window.location.origin;
  }

  const meta = {
    title: title ? (title+' | '+defaults.siteName) : (defaults.title+' | '+defaults.siteName),
    description: description || defaults.description,
    url: new URL(path, defaults.baseUrl),
    image: image || false,
    author: author || defaults.author
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
    >
      <title>{meta.title}</title>
      <link rel="canonical" href={meta.url} />
      <meta name="description" content={meta.description} />
      {image && <meta name="image" content={meta.image} />}

      <meta property="og:url" content={meta.url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      {image && <meta property="og:image" content={meta.image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={meta.author} />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      {image && <meta name="twitter:image" content={meta.image} />}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
