import React from 'react'
// Style
import { Container } from './Container1200.styles';

const Container1200 = ({
  children,
  className,
  flex
}) => (
  <Container className={className} flex={flex}>
    {children}
  </Container>
)

export default Container1200