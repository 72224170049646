import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.footer`
  width: 100%;
  display: flex;
  padding: 40px 0;
  background-color: ${c.greenDark};
`;

export const Copyright = styled.p`
  font-size: 1.6rem;
  color: ${c.grayText};
`;
